/*** About Us **/
@import url('../css/main-v2.css');
.aboutUs{
    padding-top:100px;
}
.our-services-block .card:hover{
    background: #f7560f;
        border: 2px solid #f7560f !important;
}
.our-services-block .card:hover .service-icon-title{
    color: #fff !important;
}
.service-accordians .accordion-button:not(.collapsed){
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}
.service-accordians .no-arrow-scrollButton .accordion-button::after {
    background-image: none !important;
}
.service-accordians .no-arrow-scrollButton .accordion-button{
    background: #ffffff !important;
}
.service-accordians .no-arrow-scrollButton .accordion-button:not(.collapsed){
    background-color: #ffffff !important;
}
.service-accordians .accordion-button:not(.collapsed){
    background: #f9fbfe;
    background-color: #f9fbfe !important;
    color:#ffffff;
    box-shadow: none !important;
}
.our-services-block .card{
    border: 2px solid #7f7d7d !important;
}
.about-powerz-title{
    font-size: 50px;
    color: #000646;
    text-align: center;
    position: relative;
    z-index: 999;
    line-height: 42px;
}
.about-title-center {
    padding-right: 50px;
    display: flex;
    justify-content: center;
    display: flex;
}
.about-title-center::after{
    content: '';
    width: 100px;
    height: 100px;
    background: url('../Images/iPowerz-logo-icon-01.svg');
    position: absolute;
    border-radius: 50%;
    /* top: 10px; */
    margin-top: -30px;
    display: inline-block;
}
/** Vison & Mission **/
.aboutUs-title{
    color: #000646;
    font-size: 32px;
    font-weight: bold;
}
.visionMission p{
    font-size: 22px;
    margin-bottom: 0px;
    padding-bottom: 20px;
}
/** WHat We Do **/
.whatWeDo p{
    font-size: 22px;
    margin-bottom: 0px;
    padding-bottom: 20px;
}
/** Ipowerz Fast Track **/
.ipowerzFastTrack .accordion-item {
    background-color: #fff;
    border: none;
    color: #000646;
    border-bottom: 2px solid #d3d3d3 !important;
    outline: none !important;
}
.ipowerzFastTrack .accordion-button {
    position: relative;
    display: flex;
    color: #000646;
    padding:20px;
    font-size: 24px;
    outline: none !important;
}
.accordion-button:focus{
    box-shadow: none !important;
    border:none !important;
}
.accordion-button::after{
    background-image: url('../Images/add.png');
}
.ipowerzFastTrack .accordion-button:not(.collapsed) {
    background-color: #f9fbfe;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
    color: #f7560f !important;
}
.ipowerzFastTrack .accordion-body p{
    font-size: 24px;
}
.accordion-button:not(.collapsed)::after{
    background-image: url('../Images/minus.png');
}
/** Team Seciton **/
.team-about-block{
    background: #f9fbfe;
    padding-top: 40px;
    padding-bottom: 40px;
    font-size: 24px;
    text-align: center;
}
.team-about-block p a{
    color: #f7560f;
    text-decoration: none;
}
@media (max-width:700px) {
    .mobile-hide{
        display:none;
    }
}
.Team-title{
    padding-top:100px;
}
.profile-block{
    text-align: center;
}
.profile-block .profile-image img{
    width: 200px;
}
.profile-title{
    font-size: 28px;
    color: #000646;
}
.profile-title a {
    font-size: 28px;
    color: #000646;
    text-decoration: none;
}
.profile-title a:hover{
    color:#f7560f;
}
.profile-profession{
    color: #3C4067 !important;
    font-size: 22px;
}
.team-ipowerz{
    padding-top:60px;
}
.bg-team{
    background-image: url('../Images/iPowerz-bg-01.png');
    background-size: cover;
    padding-bottom: 60px;
}
.aboutUs-bg{
    background-image: url('../Images/iPowerz-bg-01.png');
    background-size: cover;
}
.contactUsForm{
    padding-top:50px;
    padding-bottom: 50px;
}
.contactUsForm p{
    font-size: 24px;
}
.contactUsForm p a{
    color: #f7560f;
    text-decoration: none;
}
.ipowerzFastTrack{
    padding-bottom: 40px;
}
.coming-soon{
    min-height: 350px;
    display: flex;
    align-self: center;
    align-items: center;
    text-align: center;
    font-size: 32px;
    color: #000646;
}
.privacyPolicy{
    padding-top: 60px;
}
.privacyPolicy p{
    font-size: 22px;
    margin-bottom: 0px;
    padding-bottom: 20px;
}
.privacyPolicy .accordion-button {
    position: relative;
    display: flex;
    color: #000646;
    padding: 20px;
    font-size: 24px;
    outline: none !important;
}
.privacyPolicy .accordion-item {
    background-color: #fff;
    border: none;
    color: #000646;
    border-bottom: 2px solid #d3d3d3 !important;
    outline: none !important;
}
.privacyPolicy ul li{
    font-size: 22px;
    margin-bottom: 0px;
    padding-bottom: 20px;
    color:#3c4067 !important;
}
.coming-soon-title{
    font-size: 36px;
}
.form-check label{
    font-size: 24px;
}
.our-services-block .card-body{
    min-height: auto;
    text-align: center;
    font-size: 24px;
}
.our-services-block{
    padding-top:80px;
    padding-bottom: 80px;
}
.service-icon img{
    width:64px;
}
.service-icon-title{
    font-size: 24px;
    line-height: initial;
    color: #000646;
}
.services-cards{
    margin-top:40px;
}
@media (min-width:700px) {
    .services-block-btn-p{
        border-left: 1px solid #d3d3d3;
    }
}
.services-block-btn-p p{
    font-size: 22px;
    margin-bottom: 0;
    padding-left: 20px;
    padding-right: 20px;
    font-weight: 400;
    font-family: "Founders Grotesk",-apple-system,BlinkMacSystemFont,"Helvetica Neue",Helvetica,Arial,sans-serif;

}
.service-accordians .accordion-button:focus{
    border:1px solid #d3d3d3 !important;
}

.service-accordians .accordion-button::after{
    background-image: url('../Images/downArrow.svg') !important;
}
.service-accordians .accordion-body{
    font-size: 22px;
    line-height: initial;
    padding-top: 30px;
    padding-bottom: 30px;
}
.service-accordians .accordion-item{
    border:none !important;
    background-color:transparent;
}
.service-accordians .border-top-none{
    border-top:none !important;
}
.service-accordians .accordion-header{
}
.service-accordians .accordion-body{
    border: 1px solid #d3d3d3;
    margin-top: -3px;
    border-top: none !important;
    border-bottom-left-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
}
.service-accordians .accordion-header .accordion-button{
    background-color: transparent;
    padding-top:18px;
    padding-bottom: 18px;
    border: 1px solid #d3d3d3;
    margin-top: 30px;
}
.service-accordians .accordion-header .collapsed{
    border-radius: 5px !important;
}
/** Press and Media **/
.new-Section-block{
    background: #f9fbfe;
    padding-top:60px;
    padding-bottom: 60px;
}
.new-Section-block p{
    font-size: 22px;
    line-height: initial;
    min-height: 160px;
    margin-top: 20px;
}
.bullet-tips-list li strong{
    font-family: 'Montserrat', sans-serif;
}
.new-Section-block .card-body{
    min-height: auto !important;
    height: auto;
}
.news-cards{
    margin-top:30px;
}
.news-cards:hover{
    background: #f7560f;
}
.news-cards:hover .card-text{
    color: #ffffff !important;
}
.news-cards:hover .card-link-read{
    color: #ffffff !important;
}
.dropdown-item {
    font-size: 22px;
    color: #3d2895;
    text-decoration: none;
    background-color: #f9fbfe;
}
.dropdown-menu {
    color: #472a80;
    background-color: #f9fbfe;
}
.dropdown .btn-primary{
    background: transparent;
    border-radius: 0px;
    border: none !important;
    color: #3d287b;
    font-size: 22px;
    font-weight: normal;
    padding-bottom: 0px;
}
.navbar-light .navbar-nav .active:hover{
    border-bottom: 0px !important;
}
.bullet-tips-list{
    padding-left: 20px;
}
.bullet-tips-list li::marker{
    font-size: 24px;
    color:#f7560f;
}
.card-link-read{
    color:#f7560f;
}
/** Blog **/
.blog-content-section{
    margin-top: 60px;
    padding-bottom: 70px;
}
.block-title{
    font-size: 28px;
    text-align: center;
}
.block-content-display{
    margin-top: 60px;
}
.blog-content-section .widget{
    margin-top:20px;
}
.blog-title-block{
    border-bottom: 2px solid #d3d3d3;
    padding-bottom: 10px;
    /* margin-bottom: 30px; */
    margin-bottom: 1.5rem;
}
@media (max-width:330px) {
    .topbar-flex .btn {
        background: #c1c1c1;
        border: 1px solid #c1c1c1;
        color: #321260;
        font-weight: normal;
        padding-left: 8px !important;
        padding-right: 8px !important;
        font-size: 10px !important;
        border-radius: 25px;
        width: 100%;
        box-shadow: none !important;
    }
    .topbar-flex .mobile-width-50 {
        padding-left: 5px;
        padding-right: 5px;
    }
}
.date{
    color: #9e9e9e;
}
.blog-subtitle{
    margin-top:10px;
    font-size: 22px;
}
.block-content-display p{
    font-size: 24px;
    line-height: initial;
}
.block-content-display div{
    margin-top: 40px;
}    
@media (min-width:700px) {
    .blog-section-block{
        padding-right:30px;
    }
    .widget img {
       max-width: 250px;
       width: 25%;
    }
}
.blog-about{
    background: #f9fbfe;
    padding: 20px;
    border:1px solid #d3d3d3;
    border-radius: 10px;
}
.blog-aboutTitle{
    font-size: 22px;
    font-family: 'Montserrat', sans-serif;
}
.blog-about img{
    margin: 0 10px 0 0;
}
#about img {
    float: left;
}
@media (max-width:700px) {
    .scroll-your-role{
        font-size: 18px !important;
    border-radius: 10px !important;
    }
}